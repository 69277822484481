<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <span v-if="typeEmail==='ALL'">Send Notification to ALL licensees</span>
        <span v-else>Send Notification</span>
      </div>
      <b-button
        v-if="stage === 1 || stage === 3"
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body content">
      <p>Notification will be sent to:</p>
      <b-table
        :data="myData"
        :checked-rows.sync="checkedRows"
        :is-row-checkable="(row) => row.id"
        checkable>
        <b-table-column v-slot="props" field="name" label="Name">
          {{ props.row.firstName }} {{ props.row.lastName }}
        </b-table-column>
        <b-table-column v-slot="props" field="email" label="Email">
          {{ props.row.username }}
        </b-table-column>
        <b-table-column v-slot="props" field="licensee" label="Licensee">
          {{ props.row.licenseeName }}
        </b-table-column>
        <b-table-column v-slot="props" field="iconStatusCheck" label=" ">
          <b-icon v-show="props.row.iconStatusCheck===2" type="is-danger" icon="times" />
          <b-icon v-show="props.row.iconStatusCheck===1" type="is-success" icon="check" />
          <b-icon
            v-show="props.row.iconStatusCheck===0"
            pack="fas"
            icon="sync-alt"
            custom-class="fa-spin" />
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left" />
        <div v-if="hasErrors.length > 0 && !finished" class="is-size-5 has-text-danger">
          Some emails weren't sent. Try again?
        </div>
        <div v-if="!sendingEmails" class="level-right">
          <!-- <button v-if="hasErrors.length === 0 && !finished || hasErrors.length === checkedRows.length" class="button is-transparent has-text-grey" type="button" @click="close"> -->
          <button v-if="stage === 1 || stage === 3" class="button is-transparent has-text-grey" type="button" @click="close">
            Close
          </button>
          <!-- <button v-if="checkedRows.length>0" class="button" :class="hasErrors.length > 0 ? 'is-danger' : ' is-success'" @click="startProcess"> -->
          <button v-if="stage === 1 || stage === 3" class="button" :class="stage === 3 ? 'is-danger' : ' is-success'" @click="startProcess">
            {{ buttonSendText }}
          </button>
        </div>
        <!-- <div class="level-right" v-if="finished || hasErrors.length > 0 && checkedRows.length !== hasErrors.length && !sendingEmails"> -->
        <div v-if="(stage === 3 || stage === 4) && hasErrors.length === 0" class="level-right">
          <button class="button is-success" @click="finishProcess">
            Done
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import EmailsApi from '@/apis/EmailsApi'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    file: {
      required: true,
      type: Object
    },
    fileRevision: {
      required: true,
      type: Object
    },
    licenseeId: {
      default: null,
      type: Number
    },
    userRecipients: {
      required: true,
      type: Array
    },
    typeEmail: {
      default: 'ALL',
      type: String
    }
  },
  data () {
    return {
      checkedRows: [],
      loaded: false,
      finished: false,
      myData: [],
      notificationEmail: {
        emailTo: {
          email: null,
          name: null
        },
        subject: null,
        textPart: null,
        body: null
      },
      response: {
        sent: false,
        project: null
      },
      hasErrors: [],
      sendingEmails: false,
      stage: 0,
      buttonSendText: 'Send'
    }
  },
  computed: {
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    }
  },
  async mounted () {
    /*
    *  =====================================================
    *  | BUTTON    | STAGE 1 | STAGE 2 | STAGE 3 | STAGE 4 |
    *  |           |SELECTION| SENDING | ERRORS  | SUCCESS |
    *  =====================================================
    *  | SEND      |    V    |         |         |         |
    *  | RESEND    |         |         |    V    |         |
    *  | CANCEL    |    V    |         |    V    |         |
    *  | CLOSE X   |    V    |         |    V    |         |
    *  | FINISH    |         |         |    V    |    V    |
    *  =====================================================
    */
    // Set all users as not sent yet
    this.userRecipients.map(user => {
      user.iconStatusCheck = -1
      if (this.licenseeId) {
        if (user.licenseeId === this.licenseeId) {
          // this.myData.push(user) With push the array wont work!!
          this.myData.push(Object.assign({}, user))
        }
      } else {
        if (this.licensees.find(l => l.id === user.licenseeId)) {
          this.myData.push(Object.assign({}, user))
        } else {
          if (user.type === 'jfe') {
            this.myData.push(Object.assign({}, user))
          }
        }
      }
    })
    this.myData.map(user => {
      if (!user.downloaded) {
        this.checkedRows.push(user)
      }
    })

    this.myData = this.orderBy(this.myData, 'licenseeName')
    this.stage = 1
    this.loaded = true
    this.buttonSendText = 'Send'
  },
  methods: {
    startProcess () {
      if (this.checkedRows.length > 0) {
        this.sendingEmails = true
        this.finished = false
        this.hasErrors = []
        this.stage = 2
        this.response.sent = false
        // Start the process to send email foreach selected user
        this.loop().then(async (sent) => {
          if (sent) {
            this.stage = 4
            this.$buefy.toast.open('Notification Sent')
            this.finished = true
          } else {
            this.stage = 3
            this.$buefy.toast.open('Some emails were not sent.')
          }
          this.sendingEmails = false
        })
      } else {
        this.$buefy.toast.open('You need to select at least one email')
      }
    },
    finishProcess () {
      this.$emit('sent', this.response)
      this.$parent.close()
    },
    close () {
      this.$emit('close', this.response)
      this.$parent.close()
    },
    async loop () {
      // //===========================================
      // // SEND NOTIFICATIONS
      // //===========================================
      for (const user of this.myData) {
        if (this.checkedRows.find(r => parseInt(r.id) === parseInt(user.id))) {
          user.iconStatusCheck = 0 // Sending email
          /*
          // To Emulate Send or not send emails - Keep pressing Send button until random number get one of yours id
          // To grow the time to wait, increase the time from Math.random function
          await new Promise(resolve => setTimeout(resolve, Math.random() * 2000))
          var min=30; // Min id from your database user.id
          var max=218; // Max id from your database user.id
          var random = parseInt(Math.random() * (+max - +min) + +min);
          if(user.id === random){ //Simulate the error
            this.hasErrors.push(user)
            user.iconStatusCheck = 2 // Sent error
          }else{
            user.iconStatusCheck = 1 // Sent success
            // Now remove the selected from the checked list
            this.checkedRows.splice(this.checkedRows.indexOf(user), 1)
          }
          */
          try {
            const fileURL = process.env.VUE_APP_WEB_BASE + '/technical_documents/' + this.file.revisionId
            const fileName = this.file.name + ' Rev ' + this.fileRevision.revision || 0
            var subject = 'JFE document ' + fileName + ' is available to download'
            var body = 'Click to download the file: <a href="' + fileURL + '">' + fileName + '</a>'

            await this.sendEmailToUser(subject, body, user).then((mailResult) => {
              if (mailResult.Status !== undefined) {
                if (mailResult.Status === 'success') {
                  user.iconStatusCheck = 1 // Sent success
                  // Now remove the selected from the checked list
                  this.checkedRows.splice(this.checkedRows.indexOf(user), 1)
                  this.response.sent = true
                } else {
                  console.log('#201 Error sending email:' + mailResult.ErrorMessage)
                  this.hasErrors.push(user)
                  user.iconStatusCheck = 2
                  this.buttonSendText = 'Try again'
                }
              } else {
                // this.$buefy.dialog.alert({
                //     title: 'Error sending email',
                //     type: 'is-danger',
                //     hasIcon: true,
                //     message: '<strong>Returned message:</strong><br>' + mailResult.ErrorMessage,
                //     confirmText: 'Ok'
                // })
                console.log('#202 Error sending email:' + JSON.stringify(mailResult.ErrorMessage))
                this.hasErrors.push(user)
                user.iconStatusCheck = 2
                this.buttonSendText = 'Try again'
              }
            })
          } catch (err) {
            user.iconStatusCheck = 2
            this.buttonSendText = 'Try again'
            this.$buefy.dialog.alert({
              title: 'Error sending email',
              type: 'is-danger',
              hasIcon: true,
              message: '<strong>Returned message:</strong><br>' + JSON.stringify(err),
              confirmText: 'Ok'
            })
          }
        }
      }

      return this.response.sent
    },
    async sendEmailToUser (subject, body, user) {
      this.notificationEmail.emailTo.email = user.username
      this.notificationEmail.emailTo.name = user.firstName + ' ' + user.lastName
      this.notificationEmail.subject = subject
      this.notificationEmail.body = body
      this.notificationEmail.textPart = '<p>Thank you for your business,<br>The JFE Team<br><a href="www.jfetc.com">www.jfetc.com</a>'

      return EmailsApi.sendEmail(this.notificationEmail)
    } // end
  }
}

</script>
