<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ file.name }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-5-tablet">
        <b-field class="required" label="File name" :type="{'is-danger': errors.has('fileName')}" :message="errors.first('fileName')">
          <b-input v-model="fileName" v-validate="{required: file.id === 'new'}" name="fileName" data-vv-as="file name" />
        </b-field>
        <div v-show="file.id !== 'new'" class="columns">
          <div class="column">
            <b-field label="Connection">
              <b-select v-model="connectionSelected" expanded>
                <option v-for="connect in connections" :key="connect.id" :value="connect.id">
                  {{ connect.name }}
                </option>
                <option value="0">
                  Standard Documents
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Category">
              <b-select v-model="categorySelected" expanded>
                <option v-for="fileCategory in categories" :key="fileCategory.id" :value="fileCategory.id">
                  {{ fileCategory.name }}
                </option>
                <!-- <option>General</option>
                <option>Pin</option>
                <option>Box</option> -->
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="padding-xy-3 padding-xy-5-tablet">
        <div class="columns is-vcentered is-mobile section-title">
          <div class="column">
            Revisions
          </div>
          <div class="column is-narrow">
            <b-button icon-left="plus" type="is-primary" rounded @click="addRevision()">
              Add Revision
            </b-button>
          </div>
        </div>
        <autocomplete-single
          v-if="file.id !== 'new' && revisionsDataFiltered.length > 0"
          v-model="selectedLicenseeModal"
          class="field no-icon wrap-item"
          placeholder="Filter by Company"
          icon="id-badge"
          :open-on-focus="true"
          :items="licenseesFilteredByCategory(category, file)"
          text-field="name"
          value-field="id"
          name="licensee"
          autocomplete="new-password" />
        <div class="revisions_drawer" :class="{'is-toggled' : openedRevision}">
          <div v-if="addRevisionToggle" class="card" style="margin-bottom: 30px">
            <div class="padding-xy-3">
              <div class="columns">
                <div class="column">
                  <b-field label="Revision" :type="{'is-danger': errors.has('revision')}" :message="errors.first('revision')">
                    <b-input v-model="fileRevision.revision" v-validate="'required|validateRevision:fileRevision.revision'" expanded type="number" min="1" name="revision" data-vv-as="revision" />
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Date" :type="{'is-danger': errors.has('datePickerRevision')}" :message="errors.first('datePickerRevision')">
                    <b-datepicker v-model="datePickerRevision" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="datePickerRevision" data-vv-as="date" />
                  </b-field>
                </div>
              </div>
              <b-field
                class="file"
                style="border:none; background: none"
                :type="{'is-danger': errors.has('fileUp')}"
                :message="errors.first('fileUp')">
                <b-upload
                  refs="file"
                  drag-drop
                  name="fileUp"
                  data-vv-as="file"
                  @input="uploadFile(fileRevision, $event)">
                  <div v-if="fileRevision.data" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">File:</strong> {{ file.name }} Rev {{ fileRevision.revision }} <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="fileRevisionInit()" />
                  </div>
                  <a v-if="!fileRevision.data && !wrongFile" class="button is-light is-inline-flex align-center">
                    <b-icon icon="upload" class="is-clickable is-small margin-x-2" />
                    <span>Add File</span>
                  </a>
                  <div v-if="wrongFile" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">Just pdf files are permitted</strong> <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="fileRevisionInit()" />
                  </div>
                </b-upload>
              </b-field>
              <object
                v-if="fileRevision.data"
                :data="originalFileURL"
                type="application/pdf"
                width="100%"
                height="500px" />
            </div>
          </div>
          <div v-for="(revision, index) in revisionsDataFiltered" :key="revision.id" :index="index" class="card" :class="{'is-active' : openedRevision === revision.id}">
            <div class="columns is-vcentered is-mobile header is-clickable is-marginless" @click="openedRevision = revision.id; showDownloads = false">
              <div class="column is-flex">
                <div class="is-inline-flex">
                  <b-icon icon="chevron-right" class="toggle-icon" />
                  Rev {{ revision.revision }}
                  <b-tag v-if="revision.revisionEnabled" class="margin-x-2">
                    Current
                  </b-tag>
                </div>
              </div>
              <div class="column is-narrow">
                {{ revision.date | moment("MMM D, YYYY") }}
              </div>
            </div>
            <div v-if="openedRevision === revision.id" class="padding-xy-3">
              <div class="columns is-vcentered">
                <div class="column is-4">
                  <b-progress :value="downloadedByJfeUsersPercentage(file, revision)" type="is-warning" show-value format="percent" class="is-clickable" @click.native="showDownloads = !showDownloads">
                    <span class="has-text-black">{{ numberFormat(downloadedByJfeUsersPercentage(file, revision), 0) || 0 }}% Employees</span>
                  </b-progress>
                  <b-progress v-if="categorySelected !== 5" :value="downloadedByLicenseePercentage(file, revision)" type="is-success" show-value format="percent" class="is-clickable" @click.native="showDownloads = !showDownloads">
                    <span class="has-text-black">{{ numberFormat(downloadedByLicenseePercentage(file, revision), 0) || 0 }}% Licensees</span>
                  </b-progress>
                </div>
                <div class="column has-text-right">
                  <!-- <a :href="downloadFile(file)"><b-icon icon="download" size="is-small" class="margin-x-2" /></a> -->
                  <b-button
                    v-if="!revision.revisionEnabled"
                    class="is-small margin-x-2"
                    @click.stop="curretVersionGo(revision)">
                    Make Current
                  </b-button>
                  <b-button
                    class="is-small"
                    icon-left="download"
                    @click.stop="downloadFile(revision)" />
                  <b-button
                    v-if="revision.revisionEnabled"
                    class="is-small"
                    icon-left="envelope"
                    @click="openSendNotificationModal(file, revision)" />
                  <b-button v-if="!revision.revisionEnabled" class="is-clickable margin-x-2" icon-left="times-circle" size="is-small" type="is-danger" @click.native="deleteRevision(revision)" />
                </div>
              </div>
              <b-table
                v-if="selectedLicenseeModal && downloadedByFileAndRevision(file.id, revision.id).length > 0"
                :data="downloadedByFileAndRevision(file.id, revision.id)"
                class="skinny-table">
                <b-table-column
                  v-slot="props"
                  field="userId"
                  label="User">
                  {{ userById[props.row.userId].firstName }} {{ userById[props.row.userId].lastName }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="downloads"
                  label="Downloads">
                  {{ props.row.totalDownloaded }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="lastDownload"
                  label="Last Download">
                  {{ props.row.lastDownloaded | moment("MMM D, YYYY") }}
                </b-table-column>
              </b-table>
              <span v-if="selectedLicenseeModal && downloadedByFileAndRevision(file.id, revision.id).length === 0">Nobody downloaded this file yet!</span>
              <b-tabs v-if="showDownloads && !selectedLicenseeModal" type="is-boxed" expanded>
                <b-tab-item label="Not Downloaded">
                  <b-table
                    :data="revisionNotDownloaded(file, revision)"
                    class="skinny-table no-header">
                    <b-table-column
                      v-slot="props"
                      field="id"
                      label="Licensee">
                      <a @click="doFilter(props.row.id)">{{ licenseesById[props.row.id].name }}</a>
                    </b-table-column>
                  </b-table>
                </b-tab-item>
                <b-tab-item label="Downloaded">
                  <b-table
                    :data="revisionDownloaded(file.id, revision.id)"
                    class="skinny-table no-header">
                    <b-table-column
                      v-slot="props"
                      sortable
                      field="licenseeId"
                      label="Licensee">
                      <a @click="doFilter(props.row.licenseeId)">{{ licenseesById[props.row.licenseeId].name }}</a>
                    </b-table-column>
                  </b-table>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
          <b-dropdown v-if="file.id !== 'new'" :mobile-modal="false" position="is-top-right">
            <b-button
              slot="trigger"
              rounded
              type="is-dark"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item @click="deleteFile()">
              Delete File
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="level-right">
          <button class="button is-transparent has-text-grey" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-success" :disabled="wrongFile" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import FilesApi from '@/apis/FilesApi'
import SendNotificationTechFilesModal from '@/components/modals/SendNotificationTechFiles'
import Moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    connection: {
      required: true,
      type: Object
    },
    category: {
      required: true,
      type: Object
    },
    file: {
      required: true,
      type: Object
    },
    users: {
      required: true,
      type: Array
    },
    downloads: {
      required: true,
      type: Array
    },
    selectedLicensee: {
      required: false,
      type: Number,
      default: null
    }

  },
  data () {
    return {
      wrongFile: false,
      fileRevision: null,
      addRevisionToggle: false,
      openedRevision: null,
      showDownloads: false,
      loaded: false,
      connectionSelected: null,
      categorySelected: null,
      datePickerRevision: new Date(),
      selectedLicenseeModal: null,
      originalFileURL: null,
      fileName: null,
      fileDownload: {
        id: null,
        userId: null,
        fileId: null,
        revisionId: null,
        categoryId: null,
        connectionId: null
      }
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.$store.state.connections
    },
    files () {
      return this.$store.state.files
    },
    filesById () {
      const mapped = {}

      this.files.forEach(file => {
        mapped[file.id] = file
      })

      return mapped
    },
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    },
    licenseesById () {
      const mapped = {}

      this.licensees.forEach(licensee => {
        mapped[licensee.id] = licensee
      })

      return mapped
    },
    licenseeConnections () {
      return this.$store.state.licenseeConnections
    },
    categories () {
      return this.$store.state.technicalDocuments
    },
    revisionsData () {
      return this.$store.state.fileRevisions
    },
    revisionsDataFiltered () {
      return this.orderBy(this.revisionsData.filter(f => f.fileId === this.file.id && !f.deleted), 'revisionEnabled', -1)
    },
    jfeUsers () {
      return this.users.filter(u => u.type === 'jfe' && u.hasJfeAdminPermission && !u.deleted)
    },
    userById () {
      const mapped = {}

      this.users.forEach(user => {
        mapped[user.id] = user
      })

      return mapped
    },
    fileRevisionById () {
      const mapped = {}

      this.revisionsDataFiltered.forEach(fileRevision => {
        mapped[fileRevision.id] = fileRevision
      })

      return mapped
    },
    usersByLicenseeId () {
      if (this.selectedLicenseeModal) {
        return this.users.filter(u => u.licenseeId === this.selectedLicenseeModal && u.enabled && !u.deleted)
      } else {
        return this.users.filter(u => u.enabled && !u.deleted)
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('refreshTechnicalDocuments')
    await this.$store.dispatch('refreshFileRevisions', this.file.id)
    await this.$store.dispatch('refreshLicensees')
    await this.$store.dispatch('refreshLicenseeConnections')

    this.connectionSelected = this.connection.id
    this.categorySelected = this.category.id
    this.fileRevisionInit()
    this.selectedLicenseeModal = this.selectedLicensee

    if (this.file.id !== 'new') {
      this.fileName = this.file.name
    }

    this.$validator.extend('validateRevision', {
      getMessage: field => 'Revision is already in use!',
      validate: async value => {
        try {
          const file = this.files.find(f => f.id === this.file.id && parseInt(f.revision) === parseInt(value))
          if (file) {
            return false
          } else {
            return true
          }
        } catch (err) {
          return true
        }
      }
    })

    this.loaded = true
  },
  methods: {
    fileRevisionInit () {
      this.wrongFile = false
      this.fileRevision = {
        id: null,
        fileId: this.file.id,
        revision: null,
        type: null,
        data: null,
        date: null,
        revisionEnabled: true
      }
    },
    async save () {
      if (await this.$validator.validateAll()) {
        var file = this.file
        if (file.id === 'new') {
          file.name = this.fileName
          file.connectionId = this.connection.id
          file.categoryId = this.category.id
          file.userId = this.profile.id
          file.deleted = false
          file = await FilesApi.createFile(file)
        } else {
          file.name = this.fileName
          file.connectionId = this.connectionSelected
          file.categoryId = this.categorySelected
          file = await FilesApi.updateFile(file.id, file)
        }
        this.$buefy.toast.open({
          message: 'File has been saved',
          type: 'is-success'
        })
        if (this.addRevisionToggle) {
          // Reset other revisions
          await FilesApi.resetFileRevision({ id: file.id })
          // Create the new revision
          this.fileRevision.date = Moment(this.datePickerRevision).format('YYYY-MM-DD') + ' ' + Moment().format('HH:mm:ss')
          this.fileRevision.revisionEnabled = true
          this.fileRevision.fileId = file.id
          await FilesApi.createFileRevision(this.fileRevision)
          this.$buefy.toast.open({
            message: 'A new revision file has been saved',
            type: 'is-success'
          })
        }
        this.$emit('save')
        this.$parent.close()
      }
    },
    async curretVersionGo (revision) {
      try {
        await FilesApi.resetFileRevision({ id: revision.fileId })

        revision.revisionEnabled = true

        revision = await FilesApi.updateFileRevision(revision.id, revision)

        this.$buefy.toast.open({
          message: 'File has been saved',
          type: 'is-success'
        })
      } catch (e) {
        console.error(e)
      } finally {
        await this.$store.dispatch('refreshFileRevisions', this.file.id)
      }
    },
    async downloadFile (revision) {
      const loadingComponent = this.$buefy.loading.open({ container: null, canCancel: true })
      try {
        // Create log
        var fileDownload = this.fileDownload
        fileDownload.userId = this.profile.id
        fileDownload.fileId = revision.fileId
        fileDownload.revisionId = revision.id
        fileDownload.categoryId = this.category.id
        fileDownload.connectionId = this.connection.id
        await FilesApi.createFileDownload(fileDownload)
        await this.$store.dispatch('refreshFileDownloads')
      } catch (e) {
        console.error(e)
      } finally {
        // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
        const dateTimestamp = Moment().format('YYYYMMDDHHmmss')
        const link = document.createElement('a')
        link.href = await FilesApi.getFileRevisionDownload(revision.id, this.profile.id)
        link.download = (this.file.name + '_Rev' + revision.revision + '_' + dateTimestamp + '.' + revision.type.split('/')[1]).replace(/ /g, '_')
        link.click()
        loadingComponent.close()
      }
    },
    addRevision () {
      this.addRevisionToggle = !this.addRevisionToggle
      this.openedRevision = null
    },
    deleteRevision (revision) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this revision?',
        confirmText: 'Delete Revision',
        type: 'is-danger',
        onConfirm: async () => {
          await FilesApi.deleteFileRevision(revision.id)
          this.$buefy.toast.open('Revision Deleted')
          this.$emit('delete')
          this.$parent.close()
        }
      })
    },
    deleteFile () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this file?',
        confirmText: 'Delete File',
        type: 'is-danger',
        onConfirm: async () => {
          await FilesApi.deleteFile(this.file.id)
          this.$buefy.toast.open('File Deleted')
          this.$emit('delete')
          this.$parent.close()
        }
      })
    },
    close () {
      this.$parent.close()
    },
    usersLicenseeFiltered (licenseeId = null) {
      if (licenseeId) {
        return this.users.filter(u => u.licenseeId === licenseeId)
      } else {
        return this.users.filter(u => !!u.licenseeId) // All licensee users
      }
    },
    downloadedByFileAndRevision (fileId = null, revisionId = null) {
      return this.downloadsUniqueByLicensee(this.downloads.filter(d => d.fileId === fileId && d.revisionId === revisionId && ((this.selectedLicenseeModal) ? (d.licenseeId === this.selectedLicenseeModal) : true)), 'licenseeId')
    },
    downloadsUniqueByLicensee (myArr, prop) {
      // https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos && obj[prop]
      })
    },
    downloadsUniqueByField (myArr, prop) {
      // prop = the field that should not be repeated
      // https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos && obj[prop]
      })
    },
    downloadedByJfeUsersPercentage (file = null, revision = null) {
      // return this.downloadsMade
      // Step One - What is the category?
      // Step Two - Which licensee has access for this category
      // Step Three - How many downloads were take, at least one of each licensee
      const totalJfeUsers = this.jfeUsers
      const downloadsMade = this.downloads.filter(d => d.fileId === file.id && d.revisionId === revision.id && d.categoryId === file.categoryId).filter(d => {
        if (this.jfeUsers.find(u => u.id === d.userId)) {
          return true
        }
        return false
      })
      const totalDownloaded = this.downloadsUniqueByField(downloadsMade, 'userId').length
      const percentage = (totalDownloaded / totalJfeUsers.length) * 100
      return ((percentage > 100) ? 100 : percentage) || 0
    },
    downloadedByLicenseePercentage (file = null, revision = null) {
      if (this.selectedLicenseeModal) {
        return ((this.downloadedByFileAndRevision(file.id, revision.id).length / this.users.filter(u => u.licenseeId === this.selectedLicenseeModal && u.hasLicenseeTechnicalDataPermission).length) * 100) || 0
      } else {
        const totalLicensees = this.licenseesFilteredByCategory(this.category, file)
        const downloadsMade = this.downloads.filter(d => d.fileId === file.id && d.revisionId === file.revisionId)
        const totalDownloaded = this.downloadsUniqueByLicensee(downloadsMade, 'licenseeId').length
        const percentage = (totalDownloaded / totalLicensees.length) * 100
        return ((percentage > 100) ? 100 : percentage) || 0
      }
    },
    licenseesFilteredByCategory (category, file) {
      var licenseeConnection = null
      return this.licensees.filter(l => {
        switch (category.id) {
          case 2: // Pin
            licenseeConnection = this.licenseeConnections.find(lc => lc.licenseeId === l.id && lc.connectionId === file.connectionId)
            if (licenseeConnection) {
              return licenseeConnection.techDocsPin
            } else {
              return false
            }

          case 3: // Box
            licenseeConnection = this.licenseeConnections.find(lc => lc.licenseeId === l.id && lc.connectionId === file.connectionId)
            if (licenseeConnection) {
              return licenseeConnection.techDocsBox
            } else {
              return false
            }

          case 5: // Employee Docs
            if (this.profile && this.profile.hasJfeAdminPermission) {
              return true
            } else {
              return false
            }

          case 4: // Technical Packages
          case 1: // Public Docs
            const userLicensee = this.users.find(u => u.licenseeId === l.id && u.hasLicenseeTechnicalDataPermission) || null
            if (userLicensee) {
              licenseeConnection = this.licenseeConnections.find(lc => lc.licenseeId === l.id && lc.connectionId === file.connectionId)
              if (licenseeConnection) {
                if ((licenseeConnection.techDocsPin) || (licenseeConnection.techDocsBox)) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            } else {
              return false
            }

          default: // Public
            return true
        }
      })
    },
    revisionNotDownloaded (file = null, revision = null) {
      return this.licenseesFilteredByCategory(this.category, file).filter(l => !this.revisionDownloaded(file.id, revision.id).find(d => d.licenseeId === l.id))
      // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript/45687962
      // return this.licensees.filter(l => !this.revisionDownloaded(fileId, revisionId).find(d => d.licenseeId === l.id))
    },
    revisionDownloaded (fileId = null, revisionId = null) {
      return this.downloadedByFileAndRevision(fileId, revisionId)
    },
    async uploadFile (fileRevision, file) {
      try {
        const reader = new FileReader()

        reader.onload = async () => {
          fileRevision.type = file.type
          const typeSplited = file.type.split('/')

          if (typeSplited[1] === 'pdf') {
            fileRevision.data = reader.result

            // fileRevision.data = Base64Blob.base64ToBlob(reader.result)
            fileRevision.size = file.size
            fileRevision.fileId = this.file.id

            // Preview
            this.originalFileURL = URL.createObjectURL(file)
          } else {
            this.wrongFile = true
          }
        }

        reader.readAsDataURL(file)
        // reader.readAsArrayBuffer(file)
      } catch (e) {
        this.errors.items.push('fileRevision')
        console.error(e)
        // Snackbar.open(e)
        await this.$validator.validateAll()
      }
    },
    doFilter (licenseeId) {
      this.selectedLicenseeModal = licenseeId
    },
    openSendNotificationModal (file = null, revision = null) {
      var typeEmail = null
      var usersNotification = []

      // if (file.categoryId === 5) {
      this.jfeUsers.map(u => {
        if (this.downloads.find(d => d.fileId === file.id && d.revisionId === revision.id && d.userId === u.id)) {
          u.downloaded = true
        } else {
          u.downloaded = false
        }
        usersNotification.push(u)
      })
      // } else {
      this.usersByLicenseeId.map(u => {
        if (u.hasLicenseeTechnicalDataPermission) {
          var userCanDownload = false
          var licenseeHasPermission = this.licenseeConnections.find(l => l.licenseeId === u.licenseeId && l.connectionId === file.connectionId)
          switch (file.categoryId) {
            case 2:
              if (licenseeHasPermission) {
                userCanDownload = licenseeHasPermission.techDocsPin
              }
              break
            case 3:
              if (licenseeHasPermission) {
                userCanDownload = licenseeHasPermission.techDocsBox
              }
              break
            case 1:
            case 4:
              if (licenseeHasPermission) {
                if ((licenseeHasPermission.techDocsPin) || (licenseeHasPermission.techDocsBox)) {
                  userCanDownload = true
                }
              }
              break

            default:
              break
          }

          if (userCanDownload) {
            if (this.downloads.find(d => d.fileId === file.id && d.revisionId === revision.id && d.licenseeId === u.licenseeId)) {
              u.downloaded = true
            } else {
              u.downloaded = false
            }
            u.licenseeName = this.licenseesById[u.licenseeId] ? this.licenseesById[u.licenseeId].name : 'Licensee not found'
            usersNotification.push(u)
          }
        }
      })
      // }

      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: SendNotificationTechFilesModal,
        props: {
          file: this.file,
          fileRevision: this.fileRevisionById[revision.id],
          licenseeId: this.selectedLicenseeModal,
          userRecipients: usersNotification,
          typeEmail: typeEmail
        },
        events: {
          sent: async (response) => {
            if (response.sent) {
              this.$buefy.toast.open({
                message: 'Notifications has been sent',
                type: 'is-success'
              })
            }
          },
          close: async (response) => {
            if (response.sent) {
              this.$router.push({ name: 'royalty_tracker_projects' })
            }
          }
        }
      })
    }
  }
}

</script>
